const image1 = new URL(
  "assets/image-1.jpg?width=900&quality=72",
  import.meta.url
);
const image2 = new URL(
  "assets/image-2.jpg?width=900&quality=72",
  import.meta.url
);
const image3 = new URL(
  "assets/image-3.jpg?width=900&quality=72",
  import.meta.url
);
const image4 = new URL(
  "assets/image-4.jpg?width=900&quality=72",
  import.meta.url
);
const image5 = new URL(
  "assets/image-5.jpg?width=900&quality=72",
  import.meta.url
);
const image6 = new URL(
  "assets/image-6.jpg?width=900&quality=72",
  import.meta.url
);
const image7 = new URL(
  "assets/image-7.jpg?width=900&quality=72",
  import.meta.url
);
const image8 = new URL(
  "assets/image-8.jpg?width=900&quality=72",
  import.meta.url
);
const image9 = new URL(
  "assets/image-9.jpg?width=900&quality=72",
  import.meta.url
);
const image10 = new URL(
  "assets/image-10.jpg?width=900&quality=72",
  import.meta.url
);
const image11 = new URL(
  "assets/image-11.jpg?width=900&quality=72",
  import.meta.url
);
const image12 = new URL(
  "assets/image-12.jpg?width=900&quality=72",
  import.meta.url
);
const image13 = new URL(
  "assets/image-13.jpg?width=900&quality=72",
  import.meta.url
);
const image14 = new URL(
  "assets/image-14.jpg?width=900&quality=72",
  import.meta.url
);
const image15 = new URL(
  "assets/image-15.jpg?width=900&quality=72",
  import.meta.url
);
const image16 = new URL(
  "assets/image-16.jpg?width=900&quality=72",
  import.meta.url
);
const image17 = new URL(
  "assets/image-17.jpg?width=900&quality=72",
  import.meta.url
);
const image18 = new URL(
  "assets/image-18.jpg?width=900&quality=72",
  import.meta.url
);
const image19 = new URL(
  "assets/image-19.jpg?width=900&quality=72",
  import.meta.url
);
const image20 = new URL(
  "assets/image-20.jpg?width=900&quality=72",
  import.meta.url
);
const image21 = new URL(
  "assets/image-21.jpg?width=900&quality=72",
  import.meta.url
);
const section = document.querySelector("section").getBoundingClientRect();
let imageNames = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12,
  image13,
  image14,
  image15,
  image16,
  image17,
  image18,
  image19,
  image20,
  image21,
];
let random;
let positionX = "50%";
let positionY = "50%";
let isIOS =
  (/iPad|iPhone|iPod/.test(navigator.platform) ||
    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)) &&
  !window.MSStream;
let precisionValue = isIOS ? "highp" : "mediump";
let hydra;
let hydraCanvas;
let counter = 1;

function calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
  var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

  return { width: srcWidth * ratio, height: srcHeight * ratio };
}

function makeHydra() {
  clearCanvas();

  const img = new Image();

  hydraCanvas = document.createElement("canvas");
  hydraCanvas.width = 900;
  hydraCanvas.height = 900;

  hydra = new Hydra({
    canvas: hydraCanvas,
    detectAudio: false,
    enableStreamCapture: false,
    width: window.innerWidth,
    height: window.innerHeight,
    precision: precisionValue,
  });

  random = Math.floor(Math.random() * imageNames.length);
  img.src = imageNames[random];

  img.addEventListener(
    "load",
    () => {
      const ratio = calculateAspectRatioFit(
        img.width,
        img.height,
        section.width / 1.5,
        section.height / 1.5
      );

      hydraCanvas.style.width = `${ratio.width}px`;
      hydraCanvas.style.height = `${ratio.height}px`;
      hydraCanvas.style.left = `${positionX}px`;
      hydraCanvas.style.top = `${positionY}px`;

      hydraCanvas.animate([{ transform: "translate(-50%, -50%) scale(1)" }], {
        // timing options
        duration: 150,
        iterations: 1,
        fill: "forwards",
      });

      // setTimeout(() => {
      document.querySelector(".canvasWrapper").appendChild(hydraCanvas);
      s0.initImage(imageNames[random]);
      src(s0).out(o0);
      // }, 300);
    },
    false
  );
}

function clearCanvas() {
  if (!document.querySelectorAll("canvas")) return;
  document.querySelectorAll("canvas").forEach((canvas, index) => {
    if (index > 1) {
      document.querySelectorAll("canvas")[0] == null;
      document.querySelectorAll("canvas")[0].remove();
    }
  });
}

function handelOver(event) {
  if (event.target.classList.contains("showQrCode")) {
    document.querySelector(".qrCode").classList.add("active");
  }
}

function handelLeave(event) {
  if (event.target.classList.contains("showQrCode")) {
    document.querySelector(".qrCode").classList.remove("active");
  }
}

function handelClick(event) {
  counter++;
  if (event.target.classList.contains("button")) {
    document.querySelector("header").classList.toggle("active");
    document.querySelector(".canvasWrapper").classList.toggle("active");
    if (event.target.innerText === "h") {
      event.target.innerText = "p.co";
    } else {
      event.target.innerText = "h";
    }
    return;
  }

  if (
    event.target.classList.contains("canvas") ||
    event.target.tagName == "CANVAS"
  ) {
    const randomSeed = Math.floor((1 + Math.random() * 100) / 1000);
    positionX = event.clientX - section.left;
    positionY = event.clientY - section.top;

    document.querySelectorAll("canvas").forEach((canvas, index) => {
      canvas.style.height = `100%`;
      canvas.style.width = `100%`;
      canvas.style.top = `50%`;
      canvas.style.left = `50%`;
      canvas.style.transform = `translate(-50%, -50%)`;

      if (index === 0 && counter >= 4) {
        counter = 1;

        src(o0).modulate(noise(1.5).color(1.5, 2), 0.0006, 0.00001).out();

        makeHydra();
      } else if (counter === 2) {
        src(o0).modulate(noise(2.5).color(2, 1.5), 0.0006, 0.00001).out();
        makeHydra();
      } else if (counter === 3) {
        src(o0).modulate(noise(1.5).color(1.5, 2), 0.0006, 0.00001).out();
        makeHydra();
      }
    });
  }
}

clearCanvas();
makeHydra();
document.addEventListener("click", handelClick, false);
document
  .querySelector(".showQrCode")
  .addEventListener("mouseenter", handelOver, false);
document
  .querySelector(".showQrCode")
  .addEventListener("mouseleave", handelLeave, false);
